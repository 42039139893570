<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { SvgIcon } from "@/domains/Orders/components/order-total/icon-resolver/icon";
import icon from "@/mixins/icon";
import StepType from "../../models/steps/step.type";
import TimelineConfig from "../../models/config";
import AppTooltip from "@/features/bootstrap/Tooltip";
import AppSvg from "@/features/general/Svg";

export default {
  name: "AppTimelineStepTooltip",
  mixins: [icon],

  components: {
    AppTooltip,
    AppSvg,
  },

  props: {
    step: {
      type: StepType,
    },
    config: {
      type: TimelineConfig,
    },
  },
  methods: {
    ...mapActions("Orders", ["setOrderTimeline"]),
  },

  data() {
    return {
      icons: [new SvgIcon("exclamation")],
    };
  },

  computed: {
    ...mapGetters(["langs"]),
    ...mapState("Orders", {
      order: (state) => state.order.model,
      timeline: (state) => state.order.timeline,
    }),

    /**
     * Retorna o ícone
     * @return {array}
     */
    icon() {
      return this.hasDef(this.icons);
    },

    /**
     * Retorna a mensagem apresentada pela tooltip
     * @return {string}
     */
    tooltipMessage() {
      return `${this.langs.order["delivery-tooltip-message"]}`;
    },

    /**
     * Verifica se a tooltip pode ser exibida
     * @return {bool}
     * */
    shouldShowTooltip() {
      const isStepDelivery = this.step.isTypeOf("Delivery");
      const orderHasPayment = this.order.payment;
      const stepHasDate = this.step.date.length > 0;
      const isOrderOpen = this.order.status.type === "open";
      const isWithdraw = this.config.shipment.withdraw;

      if (
        isStepDelivery &&
        isOrderOpen &&
        stepHasDate &&
        !orderHasPayment &&
        !isWithdraw
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>

<template>
  <div class="app__tooltip d-flex" v-if="shouldShowTooltip && tooltipMessage">
    <app-tooltip
      :tooltipMessage="tooltipMessage"
      customClass="delivery-tooltip"
    >
      <app-svg :name="icon.name" />
    </app-tooltip>
  </div>
</template>
