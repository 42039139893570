<script>
import StepType from "../../models/steps/step.type";
import TimelineConfig from "../../models/config";

export default {
  name: "AppTimelineStepDate",

  props: {
    step: {
      type: StepType,
    },
    config: {
      type: TimelineConfig,
    },
    order: {},
  },

  computed: {
    /**
     * Formata uma data para o padrão DD MMM, YYYY (15 Fev, 2019)
     * - Caso passe um "time" válido junto, será adicionado à data no formato: (15 Fev 2019 08h25)
     * - Caso o "time" passado só possuir zeros(00:00:00), será exibido somente a data
     * - Caso a data for no formato "{n} Dias Úteis", apenas retorna a string sem formatação
     *
     * @param {string} date String de data, no formato DD/MM/YYYY ou DD/MM/YYYY H:mm:ss
     * @return {string} Data formatada
     */
    date() {
      const date = this.step.date;
      const hasOrder = typeof this.order !== "undefined";
      const orderNotCanceled =
        hasOrder && this.order.status.type !== "canceled";

      if (!date) {
        return null;
      }
      if (date.indexOf("Dia") !== -1) {
        return date;
      }

      if (!orderNotCanceled && this.step.isTypeOf("Delivery")) {
        return null;
      }

      const DATETIME_FULL_LENGTH = 14;
      const format = {
        input: "DD/MM/YYYY",
        output: "DD MMM, YYYY",
      };

      const isDateWithTime =
        date.replace(/[^0-9]/g, "").length === DATETIME_FULL_LENGTH;
      if (isDateWithTime) {
        format.input = "DD/MM/YYYY H:mm:ss";
        format.output = "DD MMM, YYYY H[h]mm";
      }

      this.$dateTime.locale("pt-br");
      const dateObject = this.$dateTime(date, format.input);

      if (!dateObject.isValid()) {
        return date;
      }
      if (!this.config.isMobile) {
        format.output = "DD/MM/YYYY";
        return dateObject.format(format.output);
      }

      const isZeroTime =
        dateObject.hour() === 0 &&
        dateObject.minutes() === 0 &&
        dateObject.seconds() === 0;

      if (isZeroTime) {
        format.output = "DD MMM, YYYY";
      }

      return dateObject.format(format.output);
    },
  },
};
</script>
<template>
  <span
    v-if="!!date"
    class="app__timeline__step__date"
    :class="{
      app__loading: config.isLoading,
      'app__timeline__step__date--brighter':
        config.isMobile && !step.isTypeOf('Delivery'),
    }"
  >
    <template v-if="config.isMobile">
      <span v-if="step.isTypeOf('Delivery')">
        Entrega prevista para {{ date }}
      </span>
      <span v-else> {{ date }} </span>
    </template>

    <template v-else> {{ date }} </template>
  </span>
</template>
