<script>
import { mapActions, mapState, mapGetters } from "vuex";
import AppAlert from "@/features/bootstrap/Alert";
import AppTooltip from "@/features/bootstrap/Tooltip";
import AppTimeline from "@/features/general/Timeline/index";
import TimelineConfig from "@/features/general/Timeline/models/config";

export default {
  name: "AppOrderStatus",
  components: {
    AppAlert,
    AppTooltip,
    AppTimeline,
  },

  mounted() {
    this.setTimelineLoading({ loading: true });
  },

  methods: {
    ...mapActions("Orders", [
      "setOrderTimeline",
      "setMainLoading",
      "setTimelineLoading",
    ]),

    /**
     * Recupera os  dados da timeline referente ao pedido.
     *
     * @return {undefined}
     */
    fetchTimeline() {
      if (!this.order.id) {
        return;
      }

      this.setTimelineLoading({ loading: true });
      this.$http
        .get(`/orders/${this.order.hash}/timeline`)
        .then((response) => {
          this.setOrderTimeline(response.data);
        })
        .finally(() => {
          this.setTimelineLoading({ loading: false });
          this.setMainLoading(false);
        });
    },
  },

  watch: {
    order(newOrder, oldOrder) {
      const needFetchTimeline = newOrder.id !== oldOrder.id;

      if (needFetchTimeline) {
        return this.fetchTimeline();
      }

      this.setTimelineLoading({ loading: false });
    },
  },

  computed: {
    ...mapState("Orders", {
      order: (state) => state.order.model,
      timeline: (state) => state.order.timeline,
      messages: (state) => state.order.messages,
      loading: (state) => state.order.timelineLoading,
    }),

    ...mapGetters(["langs", "isMobile", "shipmentTrackersRules"]),

    /**
     * Retorna o código de rastreio do pedido.
     *
     * @return {string}
     * */
    trackingCode() {
      return this.order.shipping.getTrackingCode();
    },

    /**
     * Retorna as configurações da Timeline.
     *
     * @return {TimelineConfig}
     * */
    timelineConfig() {
      return new TimelineConfig({
        isLoading: this.loading,
        showHorizontal: !this.isMobile,
        shipment: {
          isTraceable: this.isTraceable,
          withdraw: this.isWithdraw,
          trackingCode: this.trackingCode,
          trackingUrl: this.trackingUrl,
          shipmentTrackersRules: this.shipmentTrackersRules,
          loadTrackers: () =>
            this.$http.get(`/orders/${this.order.id}/tracker`),
        },
      });
    },

    /**
     * Determina se o pedido é rastreável.
     *
     * @return {bool}
     * */
    isTraceable() {
      if (this.order.shipping.isUrl(this.order.shipping.code)) {
        return false;
      }
      return this.order.shipping.is_traceable;
    },
    /**
     * Determina se o frete é retirada.
     *
     * @return {bool}
     * */

    isWithdraw() {
      return !!this.order.shipping.withdraw;
    },

    /**
     * Verifica se está na resolução desktop e se existe um código de rastreio
     * para mostrá-lo no topo da página
     *
     * @return {bool}
     */
    shouldShowTrackingCode() {
      return (
        this.trackingCode && !this.order.status.isCanceled() && !this.isMobile
      );
    },

    /**
     * Verifica se a URL de rastreio deve ser exibida
     *
     * @return {string}
     */
    trackingUrl() {
      return this.order.shipping.isValidTrackingUrl();
    },

    /**
     * Verifica se está na resolução desktop e se existe um código de rastreio
     * para mostrá-lo no topo da página
     *
     * @return {bool}
     */
    shouldShowTrackingUrl() {
      return (
        this.trackingUrl && !this.order.status.isCanceled() && !this.isMobile
      );
    },

    /**
     * Define estilo do badge
     * @return {object}
     */
    styleStatusBadge() {
      if (this.loading) {
        return {};
      }
      return {
        backgroundColor: this.order.status.background_color,
        color: this.order.status.font_color,
      };
    },

    /**
     * Define o texto do badge
     * @return {string}
     */
    badgeText() {
      return this.order.status.display_name
        ? this.order.status.display_name
        : this.order.status.store;
    },
  },
};
</script>

<template>
  <section class="app__order-status">
    <section class="app__order-status__info">
      <header class="app__order-status__header" />

      <div
        class="app__order-status__order-rectangle"
        :style="{ height: order.status.type == 'open' ? '74px' : '54px' }"
        :class="{
          app__loading: loading,
          'app__order-list__order-rectangle--open': order.status.type == 'open',
          'app__order-list__order-rectangle--closed':
            order.status.type == 'closed',
          'app__order-list__order-rectangle--canceled':
            order.status.type == 'canceled',
        }"
      />
      <div
        class="app__order-status__order-status"
        :class="{ app__loading: loading }"
      >
        {{ order.status.generic }}
      </div>
      <div class="row app__order-status__order-content">
        <div class="col-md-12">
          <span
            class="app__order-list__order-id"
            :class="{
              app__loading: loading,
              'app__order-status__id--open': order.status.type == 'open',
            }"
            data-test="customer_order_id"
          >
            #{{ order.id }}
          </span>
          <app-tooltip
            v-show="order.status.show"
            class="app__order-list__order-status-store__tooltip"
            :tooltipMessage="order.status.description"
            customClass="delivery-tooltip"
          >
            <span
              class="app__order-list__order-status-store badge"
              id="status-store__badge"
              :style="styleStatusBadge"
            >
              <span>{{ badgeText }}</span>
            </span>
          </app-tooltip>
        </div>
      </div>
      <section class="app__order-status__shipping">
        <span
          class="app__order-status__shipping-code"
          :class="{ app__loading: loading }"
          v-if="shouldShowTrackingCode"
          v-html="`${this.langs.shipping['tracking-code']} ${trackingCode}`"
        >
        </span>
      </section>
    </section>

    <app-alert :messages="messages" />

    <app-timeline
      :timeline="timeline"
      :config="timelineConfig"
      :order="order"
      data-test="customer-order-timeline-steps"
    />
  </section>
</template>
