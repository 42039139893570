<script>
import { mapState, mapGetters } from "vuex";
import StepType from "../models/steps/step.type";
import TimelineConfig from "../models/config";
import AppTimelineStepIcon from "./icon";
import AppTimelineStepAction from "./action";
import AppTimelineStepDate from "./date";
import AppTimelineStepInfo from "./info";
import AppTimelineStepTooltip from "./tooltip";
import featuresToggles from "@/mixins/featuresToggles";

export default {
  name: "AppTimelineStep",

  mixins: [featuresToggles],

  components: {
    AppTimelineStepIcon,
    AppTimelineStepInfo,
    AppTimelineStepDate,
    AppTimelineStepAction,
    AppTimelineStepTooltip,
  },

  computed: {
    ...mapState("Orders", {
      order: (state) => state.order.model,
      loading: (state) => state.order.timelineLoading,
    }),

    ...mapGetters(["isMobile", "shipmentTrackersRules"]),

    /**
     * Retorna uma instância de TimelineConfig com as configurações da Timeline.
     *
     * @return {TimelineConfig}
     * */
    getTimelineConfigurations() {
      return new TimelineConfig({
        isLoading: this.loading,
        showHorizontal: !this.isMobile,
        shipment: {
          isTraceable: this.isTraceable,
          trackingCode: this.trackingCode,
          withdraw: this.isWithdraw,
          trackingUrl: this.trackingUrl,
          shipmentTrackersRules: this.shipmentTrackersRules,
          loadTrackers: () =>
            this.$http.get(`/orders/${this.order.id}/tracker`),
        },
      });
    },

    /**
     * Verifica se existe alguma exceção para rastrear o pedido dentro da central.
     *
     * @return {bool}
     * */
    hasTrackingException() {
      const exceptionRules = {
        name: "jadlog",
      };

      return this.shippingName?.toLowerCase().includes(exceptionRules.name);
    },

    /**
     * Determina se o pedido é rastreável.
     *
     * @return {bool}
     * */
    isTraceable() {
      if (this.hasTrackingException) {
        return false;
      }

      if (this.order.shipping.isUrl(this.order.shipping.code)) {
        return false;
      }

      return this.order.shipping.is_traceable;
    },
    /**
     * Determina se o frete é retirada.
     *
     * @return {bool}
     * */
    isWithdraw() {
      return !!this.order.shipping.withdraw;
    },

    /**
     * Retorna o código de rastreio do pedido.
     *
     * @return {string}
     * */
    trackingCode() {
      return this.order.shipping.getTrackingCode();
    },

    /**
     * Verifica se a URL de rastreio deve ser exibida
     *
     * @return {string}
     */
    trackingUrl() {
      return this.order.shipping.isValidTrackingUrl();
    },

    /**
     * Retorna o nome da forma de envio
     *
     * @return {string}
     */
    shippingName() {
      return this.order.shipping.name;
    },

    /**
     * Verifica o passo da timeline e se a toggle para omitir
     * as informações de entrega esta ativa na loja
     *
     * @return {bool}
     */
    hasToHideDeliveryInfos() {
      if (
        this.step.name === "Delivery" &&
        this.isToggleEnabled("hide_delivery_date") &&
        !this.isWithdraw
      ) {
        return true;
      }

      return false;
    },
  },

  props: {
    step: {
      type: StepType,
    },
  },
};
</script>

<template>
  <div
    :data-test="`timeline-step-${step.getStepName().toLowerCase()}`"
    class="app__timeline__step"
    :class="{
      'app__timeline__step--current-step':
        step.current && !getTimelineConfigurations.isLoading,
      [`app__timeline__step--${step.status}-step`]:
        !getTimelineConfigurations.isLoading,
    }"
  >
    <app-timeline-step-icon
      :config="getTimelineConfigurations"
      :step="step"
      :order="order"
    />

    <div class="app__timeline__step__wrapper">
      <div class="app__timeline__step__info">
        <app-timeline-step-info
          :config="getTimelineConfigurations"
          :step="step"
        />

        <div
          class="app__timeline__step__date-action-wrapper"
          v-if="!hasToHideDeliveryInfos"
        >
          <app-timeline-step-tooltip
            v-if="!getTimelineConfigurations.isMobile"
            :config="getTimelineConfigurations"
            :step="step"
          />

          <app-timeline-step-date
            :config="getTimelineConfigurations"
            :step="step"
            :order="order"
          />
        </div>

        <app-timeline-step-action
          v-if="getTimelineConfigurations.isMobile"
          :config="getTimelineConfigurations"
          :step="step"
          :order="order"
        />
      </div>
    </div>

    <app-timeline-step-action
      v-if="!getTimelineConfigurations.isMobile"
      :config="getTimelineConfigurations"
      :step="step"
    />
  </div>
</template>
