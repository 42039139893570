/* eslint-disable class-methods-use-this */

import BaseModel from "@/store/models/base";

export default class TimelineConfig extends BaseModel {
  /**
   * {@inheritDoc}
   * */
  getSchema() {
    return {
      isLoading: false,
      showHorizontal: false,
      shipment: {
        isTraceable: false,
        withdraw: 0,
        trackingCode: null,
        shipmentTrackersRules: [],
        loadTrackers: () => new Promise(),
      },
    };
  }

  /**
   * Determina se a resolução é de dispositivo móvel.
   *
   * @return {boolean}
   * */
  get isMobile() {
    return !this.showHorizontal;
  }
}
