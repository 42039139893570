<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "AppOrderAddress",

  methods: {
    /**
     * Retorna o endereço ativo do usuario
     * @returns {Array} uma lista com o endereço do usuario
     */
    address() {
      return this.list.addresses.find((address) => address.type === "1");
    },

    /**
     * @return {string} Cidade
     */
    city() {
      return this.address().city;
    },

    /**
     * @return @return {string} Bairro
     */
    neighborhood() {
      return this.address().neighborhood;
    },

    /**
     * @return {string} Número
     */
    number() {
      return this.address().number;
    },

    /**
     * @return {string} Destinatário
     */
    recipient() {
      return this.address().recipient;
    },

    /**
     * @return {string} Estado
     */
    state() {
      return this.address().state;
    },

    /**
     * @return {string} Rua
     */
    street() {
      return this.address().street;
    },

    /**
     * @return {string} CEP
     */
    zipCode() {
      return this.address().zip_code;
    },

    /**
     * @return {string} Complemento
     */
    complement() {
      return this.address().complement;
    },

    /**
     * @return {string} Email
     */
    email() {
      return this.list.customer.email;
    },

    /**
     * Valida se existe ao menos um produto virtual no pedido
     */
    hasVirtualProduct() {
      return (
        this.list.products.filter((product) => product.is_virtual).length > 0
      );
    },
  },

  computed: {
    ...mapGetters(["langs"]),
    ...mapState("Orders", {
      list: (state) => state.order.list,
      orderLoading: (state) => state.order.loading,
    }),
  },
};
</script>

<template>
  <section class="app__order-address" v-if="address()">
    <h3
      class="app__order-address__title order__address__title--3"
      :class="{ app__loading: orderLoading }"
    >
      {{ this.langs.summary["delivery-address"] }}
    </h3>

    <strong
      class="app__order-address__recipient"
      :class="{ app__loading: orderLoading }"
    >
      {{ recipient() }}
    </strong>

    <span
      class="app__order-address__street"
      :class="{ app__loading: orderLoading }"
    >
      {{ `${street()}, ${number()}` }}
    </span>

    <span
      class="app__order-address__state"
      :class="{ app__loading: orderLoading }"
    >
      {{ `${neighborhood()}, ${city()} - ${state()}` }}
    </span>

    <span
      v-if="complement()"
      class="app__order-address__complement"
      :class="{ app__loading: orderLoading }"
    >
      {{ complement() }}
    </span>

    <span
      class="app__order-address__zip-code"
      :class="{ app__loading: orderLoading }"
    >
      {{ this.langs.summary["postal-code"] }}: {{ zipCode() }}
    </span>

    <span
      class="app__order-address__email"
      :class="{ app__loading: orderLoading }"
      v-if="this.hasVirtualProduct()"
    >
      Email: {{ email() }}
    </span>
  </section>
</template>
