<script>
import { defineAsyncComponent } from "vue";
import { mapState } from "vuex";
import AppError from "@/features/general/Error";
import StepType from "../../../models/steps/shipment-step";
import TimelineConfig from "../../../models/config";
import TimelineModel from "../../../models/timeline";

export default {
  name: "AppTimelineActionOrderShipment",

  components: {
    AppError,
    AppTimeline: defineAsyncComponent(() => import("../../../index")),
  },

  data() {
    return {
      activeModalTracker: false,
      trackerLoading: false,
      trackerError: false,
      trackerErrorCode: null,
      tracking: {
        loaded: false,
        timeline: new TimelineModel(),
        config: new TimelineConfig(),
      },
    };
  },

  props: {
    step: {
      type: StepType,
    },
    config: {
      type: TimelineConfig,
      default: () => new TimelineConfig(),
    },
    cssClass: {
      type: String,
      default: "",
    },
    order: {},
  },

  methods: {
    /**
     * Recupera os dados da timeline referente ao rastreio
     *
     * @return {undefined}
     */
    fetchCommerceTracker() {
      this.trackerError = false;
      this.trackerLoading = true;
      this.shippingConfig
        .loadTrackers()
        .then((response) => {
          const steps = response.data.data.items
            .map((item) => ({
              status: "closed",
              date: item.date,
              label: [item.status, item.local],
              current: item.complete,
            }))
            .reverse();

          this.tracking.timeline = new TimelineModel(steps);
          this.tracking.loaded = true;
        })
        .catch((error) => {
          this.trackerError = true;
          this.trackerErrorCode = error ? error.status : null;
        })
        .finally(() => {
          this.trackerLoading = false;
        });
    },
  },

  computed: {
    ...mapState("Orders", {
      model: (state) => state.order.model,
      timelineLoading: (state) => state.order.timelineLoading,
    }),
    /**
     * Retorna as configurações de envio.
     *
     * @return {object}
     * */
    shippingConfig() {
      return this.config.shipment;
    },

    /**
     * Retorna a URL de rastreio
     *
     * @return {string}
     * */
    trackingUrl() {
      return this.step.getActionLink() || this.shippingConfig.trackingUrl;
    },

    /**
     * Retorna se a URL de rastreio está configurada ou não
     *
     * @return {bool}
     * */
    trackingUrlNotEmpty() {
      return !!this.trackingUrl.length;
    },

    /**
     * Determina se o método de rastreio pode ser exibido dentro da central.
     *
     * @return {boolean}
     * */
    isAllowedTracker() {
      if (this.config.isMobile) {
        return false;
      }

      if (this.config.shipment.isTraceable) {
        return true;
      }

      return false;
    },

    /**
     * Determina se o conteúdo de rastreio deve ser exibido na modal.
     *
     * @return {boolean}
     * */
    shouldShowModal() {
      return this.isAllowedTracker;
    },

    /**
     * Retorna o loading do botão de rastreio
     *
     * @return {boolean}
     * */
    buttonLoading() {
      return this.trackerLoading || this.timelineLoading;
    },
  },
};
</script>

<template>
  <div class="app__timeline__step__action-shipment">
    <template v-if="!this.shouldShowModal && this.trackingUrlNotEmpty">
      <div class="app__centralizes">
        <a
          class="app__button app__button--primary-outline app__button--no-margin"
          :class="{ app__loading: buttonLoading }"
          :href="this.trackingUrl"
          target="blank"
        >
          {{ step.action.label }}
        </a>
      </div>
    </template>

    <template v-else-if="this.shouldShowModal && this.trackingUrlNotEmpty">
      <button
        v-html="step.action.label"
        data-bs-toggle="modal"
        data-bs-target="#main-modal"
        :class="cssClass"
        :data-test="`timeline-step-action-${step.getStepName().toLowerCase()}`"
        @click="fetchCommerceTracker()"
      ></button>

      <div
        ref="modal"
        class="modal fade"
        :class="[
          'app__tracking-modal',
          `app__tracking-modal--${
            this.shippingConfig.isTraceable ? 'timeline' : 'iframe'
          }`,
        ]"
        :data-test="[
          `modal-tracking-${
            this.shippingConfig.isTraceable ? 'timeline' : 'iframe'
          }`,
        ]"
        id="main-modal"
        tabindex="-1"
        aria-labelledby="main-modal-label"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="main-modal-label">Rastrear pedido</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <section
              v-if="this.shippingConfig.isTraceable && !trackerError"
              class="app__tracking-modal__timeline"
              :class="{ 'app__tracking-modal__timeline--delivered': true }"
            >
              <app-timeline
                class="app__timeline--no-margin"
                :timeline="tracking.timeline"
                :config="tracking.config"
              />
            </section>

            <app-error v-if="trackerError" class="app__error--no-margin">
              <template v-if="trackerErrorCode === 404" v-slot:title>
                Código de rastreio indisponível no momento, tente novamente mais
                tarde.
              </template>

              <span
                v-bind="{ fetchCommerceTracker: this.fetchCommerceTracker }"
              >
                <button
                  v-if="trackerErrorCode !== 404"
                  class="app__button app__button--primary app__button--no-margin"
                  v-on:click="fetchCommerceTracker"
                >
                  Tentar novamente
                </button>
              </span>
            </app-error>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
