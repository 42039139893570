<script>
import { mapState } from "vuex";
import StepType from "../../../models/steps/step.type";
import TimelineConfig from "../../../models/config";

export default {
  name: "AppTimelineActionDefault",

  props: {
    step: {
      type: StepType,
    },
    config: {
      type: TimelineConfig,
    },
    cssClass: {
      type: String,
      default: "",
    },
    order: {
      paymentMethodMessage: {
        confirmation: {
          type: String,
          default: "",
        },
      },
      payment_method: {
        type: {
          type: String,
          default: "",
        },
      },
      status: {
        type: {
          type: String,
          default: "",
        },
        store: {
          type: String,
          default: "",
        },
      },
      access_code: {
        type: String,
        default: "",
      },
    },
  },

  methods: {
    /**
     * Retorna o valor do jwtToken do localStorage
     * @return {string}
     */
    jwtToken() {
      return localStorage.getItem("jwtToken");
    },
  },

  computed: {
    ...mapState({
      store_info: (state) => state.store_info.list,
    }),

    /**
     * Valida se foi feito o pagamento
     * @return {boolean}
     */
    hasPayment() {
      return !!this.order.payment;
    },

    /**
     * Retorna o método de pagamento do pedido.
     * @return {boolean}
     * */
    isBillet() {
      return this.order.payment_method.type == "bank_billet";
    },

    /**
     * Valida se existe um boleto pendente a pagar
     * @return {boolean}
     */
    isPendingBillet() {
      return this.isBillet && this.isOrderOpen && !this.hasPayment;
    },

    /**
     * Verifica se é permitido a exibição do link de confirmação
     * @return {boolean}
     * */
    canShowLink() {
      if (this.isPendingBillet) {
        return true;
      }

      if (
        this.isOrderCanceled ||
        this.isNotConfirmationRequired ||
        this.isWireTransferWithoutPayment
      ) {
        return false;
      }

      return true;
    },

    /**
     * Verifica se o pedido está cancelado
     * @return {boolean}
     */
    isOrderCanceled() {
      return this.order.status.type === "canceled";
    },

    /**
     * Verifica se o pedido está em andamento
     * @return {boolean}
     */
    isOrderOpen() {
      return this.order.status.type === "open";
    },

    /**
     * Verifica se a opção de confirmação de pagamento está habilitada
     * @return {boolean}
     */
    isNotConfirmationRequired() {
      const isRequired =
        this.order.paymentMethodMessage.confirmation == "1" ||
        this.step.status === "closed";

      return !isRequired;
    },

    /**
     * Verifica se o tipo do pagamento é transferencia bancária
     * @return {boolean}
     */
    isWireTransfer() {
      return (
        this.hasPaymentMethodType &&
        this.order.payment_method.type === "wire_transfer"
      );
    },

    /**
     * Verifica se o pedido está aguardando um pagamento
     * @return {boolean}
     */
    isWaitingPayment() {
      return this.step.status !== "closed";
    },

    /**
     * Verifica se o o pedido é uma transferencia e está aguardando o pagamento
     * @return {boolean}
     */
    isWireTransferWithoutPayment() {
      return this.isWireTransfer && !this.isWaitingPayment;
    },

    /**
     * Verifica se existe pagamento
     * @return {boolean}
     */
    hasPaymentMethodType() {
      return typeof this.order.payment_method.type !== "undefined";
    },

    /**
     * Retorna o link para a ação do botão
     */
    linkAction() {
      let queries = `loja=${this.store_info.id}&pedido=${this.order.access_code}&status=${this.order.status.store}`;
      if (this.isWireTransfer) {
        return `${this.store_info.uri.https}/loja/central_confirmar_pagamento.php?${queries}`;
      }
      return this.step.action.link;
    },
  },
};
</script>

<template>
  <div>
    <a
      v-if="!!step.action.link && this.canShowLink"
      :href="linkAction"
      v-html="step.action.label"
      target="_blank"
      :class="cssClass"
      :data-test="`timeline-step-action-${step.getStepName().toLowerCase()}`"
    ></a>
  </div>
</template>
