<script>
import { mapState } from "vuex";
import StepType from "../../models/steps/step.type";
import TimelineConfig from "../../models/config";
import AppTimelineActionDefault from "./behaviors/default";
import AppTimelineActionOrderShipment from "./behaviors/order-shipment";
import AppTimelineActionPaymentPix from "./behaviors/payment-pix";

export default {
  name: "AppTimelineStepAction",

  components: {
    AppTimelineActionDefault,
    AppTimelineActionOrderShipment,
    AppTimelineActionPaymentPix,
  },

  props: {
    step: {
      type: StepType,
    },
    config: {
      type: TimelineConfig,
      default: () => new TimelineConfig(),
    },
  },

  computed: {
    ...mapState("Orders", {
      order: (state) => state.order.model,
    }),

    /**
     * Retorna a lista de classes de acordo com o tipo do dispositivo.
     *
     * @return {array}
     * */
    getClassList() {
      if (this.config.isMobile) {
        return [
          "app__button app__button--primary-outline app__button--no-margin-link",
          "app__text--strong",
          this.step.isCanceled() ? "app__link--danger" : "app__link",
        ];
      }

      return [
        "app__button app__button--primary-outline",
        "app__button--no-margin",
        this.step.isCanceled()
          ? "app__button--danger app__button--danger-outline"
          : "",
      ];
    },

    /**
     * Valida se deve exibir o botão de pagar pix
     * @return {boolean}
     */
    isPaymentPix() {
      const isPix =
        this.step.isTypeOf("Payment") &&
        this.order.payment_method.type === "pix" &&
        (!this.order.payment || this.order.payment.status === "open");

      return isPix;
    },

    /**
     * Retorna a classe utilizada pela ação.
     *
     * @return {string}
     * */
    className() {
      const classes = this.getClassList;
      if (this.config.isLoading) {
        classes.push("app__loading");
      }

      return classes.filter((className) => !!className).join(" ");
    },

    /**
     * Retorna o id do componente que será renderizado.
     *
     * @return {string}
     * */
    componentId() {
      if (this.step.isTypeOf("Shipment")) {
        return "app-timeline-action-order-shipment";
      }

      if (this.isPaymentPix) {
        return "app-timeline-action-payment-pix";
      }

      return "app-timeline-action-default";
    },
  },
};
</script>

<template>
  <div class="app__timeline__step__action">
    <component
      :is="componentId"
      :cssClass="className"
      :config="config"
      :step="step"
      :order="order"
    />
  </div>
</template>
