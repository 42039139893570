<script>
import { mapState, mapGetters } from "vuex";
import AppTimelineStep from "./steps/index";
import TimelineConfig from "./models/config";
import TimelineModel from "./models/timeline";

export default {
  name: "AppTimeline",
  components: {
    AppTimelineStep,
  },

  computed: {
    ...mapState("Orders", {
      loading: (state) => state.order.timelineLoading,
    }),

    ...mapGetters(["isMobile", "shipmentTrackersRules"]),
  },
  props: {
    timeline: {
      type: TimelineModel,
      default: () => {
        const steps = [
          {
            current: true,
            date: "10/08/2018",
            label: "Pedido realizado",
            status: "closed",
          },
          {
            current: false,
            label: "Pedido Cancelado",
            status: "canceled",
          },
        ];

        return new TimelineModel(steps);
      },
    },
    config: {
      type: TimelineConfig,
      default: () => new TimelineConfig(),
    },
    order: {},
  },
};
</script>

<template>
  <section
    class="app__timeline"
    :class="{
      'app__timeline--filled': !loading,
      'app__timeline--horizontal': config.showHorizontal,
    }"
  >
    <app-timeline-step
      v-for="(step, index) in timeline.steps"
      v-bind:key="index"
      :step="step"
    />
  </section>
</template>
