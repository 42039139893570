<script>
import AppIcon from "@/features/general/Icons";
import StepType from "../../models/steps/step.type";
import TimelineConfig from "../../models/config";
import AppSvg from "@/features/general/Svg";
import { mapState } from "vuex";

export default {
  name: "AppTimelineStepIcon",

  components: {
    AppIcon,
    AppSvg,
  },

  props: {
    step: {
      type: StepType,
    },
    config: {
      type: TimelineConfig,
    },
    order: {},
  },

  computed: {
    ...mapState({
      store_info: (state) => state.store_info.list,
    }),

    /**
     * Verifica se existem apenas produtos virtuais
     * @return {boolean}
     */
    onlyVirtualProducts() {
      return this.order.onlyVirtualProducts || false;
    },

    /**
     * Retorna o icone de acordo com as regras definidas
     * @return {object}
     */
    icon() {
      const toggleWithdrawal =
        this.store_info.features?.includes("toggle_withdrawal");
      const isVirtualDeliveryStep =
        this.step.name === "Shipment" && this.onlyVirtualProducts;
      const stepName = this.step.getStepName();
      const isWithdraw =
        this.config.shipment.withdraw && stepName === "Shipment";
      if (isWithdraw && toggleWithdrawal) {
        const withdrawDeliveryIcon = { name: "box" };
        return withdrawDeliveryIcon;
      }

      if (isVirtualDeliveryStep) {
        const virtualDeliveryIcon = {
          prefix: "far",
          name: "envelope",
        };

        return virtualDeliveryIcon;
      }

      return this.step.icon;
    },
  },
};
</script>
<template>
  <figure
    class="app__timeline__step__icon"
    :class="{
      [`app__timeline__step__icon--${step.status}-step`]: !config.isLoading,
      'app__timeline__step__icon--current-step':
        step.current && !config.isLoading,
    }"
  >
    <app-svg
      v-if="icon?.name === 'box'"
      :class="{ app__loading: config.isLoading }"
      :name="icon.name"
    />
    <app-icon
      v-else-if="icon"
      :class="{ app__loading: config.isLoading }"
      :prefix="icon.prefix"
      :name="icon.name"
    ></app-icon>
  </figure>
</template>
